import NavigationToggle from 'components/atoms/NavigationToggle'
import NavigationMenu from 'components/molecules/NavigationMenu'
// @ts-ignore
// TODO: fix "Cannot find module declaration"
import useMediaQuery from 'hooks/useMediaQuery'
import { FC, useMemo, useState } from 'react'
import { NavigationProps } from './Navigation.d'

const Navigation: FC<NavigationProps> = ({ items }) => {
  const isMobile = useMediaQuery(640)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => setShowMenu((prev) => !prev)

  const show = useMemo(() => {
    return !isMobile ? true : showMenu
  }, [isMobile, showMenu])

  return (
    <>
      <NavigationToggle onClick={toggleMenu} />
      <NavigationMenu
        show={show}
        items={items}
        isMobile={isMobile}
        onClick={toggleMenu}
      />
    </>
  )
}

export default Navigation

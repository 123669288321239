import { useForm as useCarryForm } from '@formcarry/react'
import Input from 'components/atoms/Input'
import Textarea from 'components/atoms/Textarea/Textarea'
import { FC } from 'react'
import { ContactFormProps } from './ComtactForm.d'

const ContactForm: FC<ContactFormProps> = ({ data }) => {
  const { state, submit } = useCarryForm({
    id: process.env.GATSBY_CARRY_FORM_ID || '',
  })

  return (
    <form onSubmit={submit} className="space-y-10 mb-10" autoComplete="off">
      <Input label={data.name} type="text" name="name" />
      <Input label={data.email} type="email" name="email" />
      <Textarea label={data.message} name="message" />
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          name="privacy"
          value="privacy"
          id="privacy"
          required
          className="accent-teal w-5 h-5 cursor-pointer"
        />
        <label htmlFor="privacy">
          {data.privacyText}{' '}
          <a href={data.privacyLink} target="_blank" className="underline">
            {data.privacyPolicy}
          </a>
        </label>
      </div>

      {!state.submitted ? (
        <button
          type="submit"
          className="uppercase px-4 py-2 border-2 hover:border-teal hover:text-teal transition-all duration-300 active:bg-teal active:text-white"
          disabled={state.submitting}
        >
          {data.submit}
          {state.submitting ? '...' : null}
        </button>
      ) : (
        <p className="mt-20 text-xl text-teal font-semibold">
          {data.emailSent}
        </p>
      )}
    </form>
  )
}

export default ContactForm

import HeroImage from 'components/atoms/HeroImage'
import MainHeading from 'components/atoms/MainHeading'
import AboutSection from 'components/molecules/AboutSection'
import AudioList from 'components/molecules/AudioList'
import ProjectList from 'components/molecules/ProjectList'
import ContactSection from 'components/organisms/ContactSection'
import Navigation from 'components/organisms/Navigation'
import Section from 'components/templates/Section'
import { FC } from 'react'
import { LayoutProps } from './Layout.d'

const Layout: FC<LayoutProps> = ({
  header,
  audioList,
  projectList,
  aboutSection,
  contact,
}) => {
  const { title, subtitle, heroImage, nav } = header
  const { text, cover } = aboutSection

  return (
    <>
      <header className="container mx-auto text-center p-5">
        <MainHeading title={title} subtitle={subtitle} />
        <Navigation items={nav} />
      </header>

      <main>
        <HeroImage image={heroImage.gatsbyImageData} />
        <Section title={nav[0].text}>
          <AudioList items={audioList} />
        </Section>
        <Section title={nav[1].text}>
          <ProjectList projectList={projectList} />
        </Section>
        <Section title={nav[2].text}>
          <AboutSection
            text={text}
            image={cover.gatsbyImageData}
            imageAlt={cover.alt}
          />
        </Section>
      </main>

      <Section
        as="footer"
        title={nav[3].text}
        className="bg-gray-dark text-white"
        titleClassName="before:bg-white after:bg-white"
      >
        <ContactSection contact={contact} />
      </Section>
    </>
  )
}

export default Layout

import { useEffect, useState } from 'react'
const isBrowser = typeof window !== 'undefined'

interface State {
  windowWidth: number
  isDesiredWidth: boolean
}

const useMediaQuery = (minWidth: number): boolean => {
  if (isBrowser) {
    const [state, setState] = useState<State>({
      windowWidth: window.innerWidth,
      isDesiredWidth: false,
    })

    const resizeHandler = () => {
      const currentWindowWidth = window.innerWidth
      const isDesiredWidth = currentWindowWidth < minWidth
      setState({ windowWidth: currentWindowWidth, isDesiredWidth })
    }

    useEffect(() => {
      resizeHandler()
    }, [])

    useEffect(() => {
      window.addEventListener('resize', resizeHandler)
      return () => window.removeEventListener('resize', resizeHandler)
    }, [state.windowWidth])

    return state.isDesiredWidth
  }

  return false
}

export default useMediaQuery

import { FC } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import { AudioItemProps } from './AudioItem.d'

const AudioItem: FC<AudioItemProps> = ({ title, url }) => {
  return (
    <div className="w-full">
      <AudioPlayer
        header={<p className="text-white">{title}</p>}
        src={url}
        showJumpControls={false}
        showSkipControls={false}
        customAdditionalControls={[]}
        layout="horizontal"
      />
    </div>
  )
}

export default AudioItem

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { Icon } from '../Icons'
import { ProjectItemProps } from './ProjectItem.d'

const ProjectItem: FC<ProjectItemProps> = ({
  title,
  description,
  cover,
  url,
  urlIcon,
  urlText,
}) => {
  const image = getImage(cover.gatsbyImageData)

  return image ? (
    <figure className="bg-white border border-gray-200 shadow flex flex-col">
      <div className="bg-black flex items-center justify-center">
        <GatsbyImage image={image} alt={cover.alt} />
      </div>

      <div className="p-5 flex-auto flex flex-col justify-between">
        <div>
          <h4 className="uppercase text-center mb-2 text-lg">{title}</h4>

          <figcaption className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            {description}
          </figcaption>
        </div>

        {url && (
          <div>
            <a
              href={url}
              target="_blank"
              className="inline-flex mt-auto items-center px-3 py-2 text-sm font-medium text-center bg-teal text-white"
            >
              <span className="text-lg">{urlText}</span>

              {urlIcon && (
                <div className="ml-2">
                  <Icon name={urlIcon} />
                </div>
              )}
            </a>
          </div>
        )}
      </div>
    </figure>
  ) : null
}

export default ProjectItem

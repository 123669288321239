import { FC } from 'react'
import { NavigationMenuItemProps } from './NavigationMenuItem.d'

const NavigationMenuItem: FC<NavigationMenuItemProps> = ({
  text,
  active = false,
  onClick,
}) => {
  return (
    <li onClick={onClick}>
      <span
        className={`block uppercase cursor-pointer py-5 sm:border-b-2 ${
          active
            ? 'bg-teal text-white sm:bg-white sm:text-black sm:border-teal'
            : 'sm:border-transparent'
        }`}
      >
        {text}
      </span>
    </li>
  )
}

export default NavigationMenuItem

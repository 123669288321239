import { Icon } from 'components/atoms/Icons'
import ContactForm from 'components/molecules/ContactForm'
import { FC } from 'react'
import { StructuredText } from 'react-datocms'
import { ContactSectionProps } from './ContactSection.d'

const ContactSection: FC<ContactSectionProps> = ({ contact }) => {
  const { social, copyrightText, contactForm, credits } = contact
  const currentYear = new Date().getFullYear()

  return (
    <div className="container mx-auto lg:px-36 2xl:px-96">
      <div className="flex items-center justify-center mb-10">
        {social.map((icon) => (
          <a
            key={icon.id}
            href={icon.url}
            target="_blank"
            className="mx-2 text-white group fill-white"
          >
            <Icon name={icon.name} />
          </a>
        ))}
      </div>

      <ContactForm data={JSON.parse(contactForm)} />

      <p className="text-center mb-2">
        © 2017 - {currentYear} {copyrightText}
      </p>

      <div className="text-center footer-links">
        <StructuredText data={credits} />
      </div>
    </div>
  )
}

export default ContactSection

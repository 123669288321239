import { FC } from 'react'
import { NavigationToggleProps } from './NavigationToggle.d'

const NavigationToggle: FC<NavigationToggleProps> = ({ onClick }) => {
  return (
    <button
      data-dropdown-toggle="dropdown"
      className="py-5 text-sm w-full block sm:hidden"
      onClick={onClick}
    >
      MENU
    </button>
  )
}

export default NavigationToggle

import { isLink, renderRule } from 'datocms-structured-text-utils'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FC } from 'react'
import { StructuredText } from 'react-datocms'
import { AboutSectionProps } from './AboutSection.d'

const AboutSection: FC<AboutSectionProps> = ({ text, image, imageAlt }) => {
  return (
    <div className="lg:flex items-center">
      <div className="mb-5 lg:mb-0 w-7/12 sm:w-4/12 mx-auto block h-auto">
        <GatsbyImage image={image} alt={imageAlt} />
      </div>
      <div className="w-full lg:w-7/12 h-auto about-text">
        <StructuredText
          data={text}
          customNodeRules={[
            renderRule(isLink, ({ node, children, key }) => {
              return (
                <a
                  className="bg-teal bg-opacity-50 text-white px-1 py-0.5 hover:text-black duration-300"
                  key={key}
                  href={node.url}
                  target="_blank"
                >
                  {children}
                </a>
              )
            }),
          ]}
        />
      </div>
    </div>
  )
}

export default AboutSection

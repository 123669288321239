import { FC } from 'react'
import { SectionProps } from './Section.d'

const Section: FC<SectionProps> = ({
  children,
  title,
  as = 'section',
  className = 'container mx-auto',
  titleClassName = 'before:bg-black after:bg-black',
}) => {
  const Component = as

  return (
    // @ts-ignore
    <Component className={`px-5 pt-14 last:pb-14 ${className}`} id={title}>
      <h3
        className={`uppercase text-2xl mb-14 before:content-[''] before:inline-block before:w-3 before:h-px before:mr-3 after:inline-block after:w-3 after:h-px after:ml-3 flex items-center justify-center ${titleClassName}`}
      >
        {title}
      </h3>
      {children}
    </Component>
  )
}

export default Section

import AudioItem from 'components/atoms/AudioItem'
import { FC } from 'react'
import 'react-h5-audio-player/lib/styles.css'
import { AudioListProps } from './AudioList.d'

const AudioList: FC<AudioListProps> = ({ items }) => {
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
      {items.map((item) => (
        <AudioItem
          key={item.id}
          title={item.media.title}
          url={item.media.url}
        />
      ))}
    </div>
  )
}

export default AudioList

import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { FC } from 'react'
import { HeroImageProps } from './HeroImage.d'

const HeroImage: FC<HeroImageProps> = ({ image }) => {
  const img = getImage(image)
  const bgImage = convertToBgImage(img)

  return (
    <BackgroundImage
      Tag="div"
      {...bgImage}
      preserveStackingContext
      className="hero-img bg-cover bg-no-repeat bg-scroll bg-center"
    />
    // <div
    //   className="hero-image bg-cover bg-no-repeat bg-scroll	bg-center"
    //   style={{ backgroundImage: `url(${url})` }}
    // />
  )
}

export default HeroImage

import { FC, FormEvent, useMemo, useState } from 'react'
import { TextareaProps } from './Textarea.d'

const Textarea: FC<TextareaProps> = ({ label, name }) => {
  const [value, setValue] = useState('')
  const onChange = (e: FormEvent<HTMLTextAreaElement>) =>
    setValue(e.currentTarget.value)

  const classes = useMemo(() => {
    if (value && value.trim()) {
      return 'border-teal'
    }

    return 'border-white'
  }, [value])

  return (
    <div className="relative">
      <textarea
        id={name}
        name={name}
        rows={5}
        className={`peer w-full h-36 border-b-2 placeholder-transparent focus:outline-none focus:border-teal bg-transparent resize-none ${classes}`}
        placeholder=" "
        required
        onChange={onChange}
      />
      <label
        htmlFor={name}
        className="absolute left-0 -top-5 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  )
}

export default Textarea

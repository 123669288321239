import ProjectItem from 'components/atoms/ProjectItem'
import { FC } from 'react'
import { ProjectListProps } from './ProjectList.d'

const ProjectList: FC<ProjectListProps> = ({ projectList }) => {
  console.log({ projectList })
  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:px-48 2xl:px-96">
      {projectList.map((item) => {
        return (
          <ProjectItem
            key={item.id}
            title={item.title}
            description={item.description}
            cover={item.cover}
            url={item.url}
            urlText={item.urlText}
            urlIcon={item.urlIcon}
          />
        )
      })}
    </div>
  )
}

export default ProjectList

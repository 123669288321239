import NavigationMenuItem from 'components/atoms/NavigationMenuItem'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { FC, useCallback, useMemo, useState } from 'react'
import { NavigationMenuProps } from './NavigationMenu.d'

const NavigationMenu: FC<NavigationMenuProps> = ({
  show,
  items,
  isMobile,
  onClick,
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(null)

  const onClickItem = useCallback(
    (item: string) => {
      if (isMobile) {
        onClick()
      }

      setTimeout(
        () => {
          setActiveItem(item)
          scrollTo(`#${item}`)
        },
        isMobile ? 200 : 0
      )
    },
    [isMobile]
  )

  const classes = useMemo(() => {
    if (isMobile && show) {
      return 'h-nav opacity-100'
    }

    if (isMobile && !show) {
      return 'h-0 opacity-0'
    }

    if (!isMobile) {
      return 'h-auto'
    }
  }, [isMobile, show])

  return (
    <nav className={`${classes} transition-all duration-300`}>
      <ul className="sm:flex sm:justify-between sm:max-w-3xl sm:mx-auto">
        {items.map((item) => (
          <NavigationMenuItem
            key={item.id}
            text={item.text}
            active={item.text === activeItem}
            onClick={() => onClickItem(item.text)}
          />
        ))}
      </ul>
    </nav>
  )
}

export default NavigationMenu
